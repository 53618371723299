import { useToken } from "@chakra-ui/react";
import Head from "next/head";

export default function PageInfo({
  overrideTitle,
  overrideOGImage,
  themeColor,
  title,
  description,
  noIndex = false,
}) {
  // Use an en-dash
  const realTitle = overrideTitle ?? title + " — AdmitYogi";
  const realOGImage = overrideOGImage ?? `/api/og/page?title=${encodeURIComponent(title)}`;
  const resolvedThemeColor = useToken("colors", themeColor, themeColor);

  if (!description) {
    description =
      "Discover how students got into your dream school. Find out how college students—with activities and stats just like yours—wrote their apps.";
  }
  return (
    <Head>
      <title>{realTitle}</title>

      <meta property="og:title" content={realTitle} />
      <meta property="og:image" content={realOGImage} />

      <meta name="description" content={description} />
      <meta property="og:description" content={description} />

      {noIndex && <meta name="robots" content="noindex, follow" />}

      {resolvedThemeColor && <meta name="theme-color" content={resolvedThemeColor} />}

      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />

      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
    </Head>
  );
}
