import { colleges, find } from "./data/colleges-data";

export const T20_SCHOOLS = [
  "Massachusetts Institute of Technology",
  "Stanford University",
  "Harvard University",
  "Columbia University in the City of New York",
  "Princeton University",
  "Duke University",
  "University of Pennsylvania",
  "Yale University",
  "Dartmouth College",
  "Cornell University",
  "University of Chicago",
  "Brown University",
  "Johns Hopkins University",
  "Northwestern University",
  "Claremont McKenna College",
  "University of Notre Dame",
  "Vanderbilt University",
  "Carnegie Mellon University",
  "Georgetown University",
  "University of Southern California",
  "Pomona College",
  "Rice University",
  "Swarthmore College",
  "Barnard College",
  "Tufts University",
];
export const DATABASE_SIZE = {
  applications: "6,000",
  essays: "9,000",
};
export const DEFAULT_CONVERSATION_NAME =
  new Date().toLocaleDateString("en-US", { month: "short", day: "numeric" }) + " Conversation";
export const DEFAULT_PFP_URL = "https://180dc.org/wp-content/uploads/2016/08/default-profile.png";
export const UNLOCKED_ESSAY_LENGTH = 200;
export const LOCKED_TOO_SHORT = "Too short to see a preview.";
export const IS_LOCKED_LOADING = "loading";
export const RANK_IN_CLASS_OPTIONS = [
  "Unranked",
  "Valedictorian",
  "Salutatorian",
  "Top 1%",
  "Top 2%",
  "Top 5%",
  "Top 10%",
  "Top 25%",
  "Top 50%",
  "Top 100%",
];
export const ETHNICITY_OPTIONS = [
  "White",
  "Asian",
  "Black or African American",
  "Hispanic",
  "Native Hawaiian or Other Pacific Islander",
  "Other",
];
export const COLLEGE_RESULT_OPTIONS = ["Accepted", "Waitlisted", "Rejected", "Withdrawn"];
export const HS_TYPE_OPTIONS = ["Public", "Private", "Homeschool", "Online", "Magnet", "Charter"];
export const AWARD_LEVEL_OPTIONS = ["International", "National", "State/Regional", "School"];
export const SEX_OPTIONS = ["Male", "Female", "Nonbinary", "Prefer not to say"];
export const redactOptionList = [
  "[Name Redacted]",
  "[School Redacted]",
  "[Organization Redacted]",
  "[Place Redacted]",
  "[URL Redacted]",
  "[Redacted Content]",
];
export const SCHOLARSHIP_OPTIONS = [
  "Coca-Coca Scholarship",
  "National Merit Scholarship",
  "Bryan Cameron Impact Scholarship",
  "University of Texas Forty Acres Scholarship",
  "USC Merit Scholarship (Full-ride)",
  "Vanderbilt Merit Scholarship (Full-ride)",
  "Davidson Fellow Scholarship",
  "U.S. Presidential Scholarship",
  "Atlas Fellowship",
  "Duke AB Scholarship",
  "Duke Robertson Scholarship",
  "Emory Woodruff Scholarship",
  "Emory Merit Scholarship",
  "Rice Merit Scholarship",
  "JHU Merit Scholarship",
  "MIT Research Science Institute",
  "UPenn Management & Technology Summer Institute",
  "Other",
];
export const ACADEMIC_INTEREST_TOP = [
  "English",
  "Mathematics",
  "History",
  "Chemistry",
  "Sociology",
  "Computer Science",
  "Biology",
  "Physics",
  "Economics",
  "Engineering",
  "Communications",
];
export const DATA_TO_IMPORT_OPTIONS = [
  {
    text: "Basic Info",
    key: "demographics",
    value: "demographics",
  },
  {
    text: "AP Tests & Academics",
    key: "academics",
    value: "academics",
  },
  {
    text: "Activities",
    key: "ecs",
    value: "ecs",
  },
  {
    text: "Awards",
    key: "awards",
    value: "awards",
  },
  {
    text: "Essays",
    key: "essays",
    value: "essays",
  },
];
export const AP_IB_EXAM_OPTIONS = [
  "AP Research",
  "AP Seminar",
  "AP Art & Design Program",
  "AP Art History",
  "AP Music Theory",
  "AP English Language & Composition",
  "AP English Literature & Composition",
  "AP Government & Politics: Comparitive",
  "AP European History",
  "AP Human Geography",
  "AP Economics: Macroeconomics",
  "AP Economics: Microeconomics",
  "AP Psychology",
  "AP Government & Politics: United States",
  "AP United States History",
  "AP World History",
  "AP Calculus AB",
  "AP Calculus BC",
  "AP Computer Science A",
  "AP Computer Science Principles",
  "AP Statistics",
  "AP Biology",
  "AP Chemistry",
  "AP Environmental Science",
  "AP Physics 1: Algebra-Based",
  "AP Physics 2: Algebra-Based",
  "AP Physics C Electricity & Magnetism",
  "AP Physics C Mechanics",
  "AP Chinese Language & Culture",
  "AP French Language & Culture",
  "AP German Language & Culture",
  "AP Italian Language & Culture",
  "AP Japanese Language & Culture",
  "AP Latin",
  "AP Spanish Language",
  "AP Spanish Literature",
  "IB Language A: Literature",
  "IB Language A: Language & Literature",
  "IB Literature & Performance",
  "IB Classical Languages",
  "IB Spanish ab Initio",
  "IB Mandarin ab Initio",
  "IB French ab Initio",
  "IB Spanish B",
  "IB Mandarin B",
  "IB French B",
  "IB Business Management",
  "IB Economics",
  "IB Geography",
  "IB Global Politics",
  "IB History",
  "IB Information Technology in a Global Society",
  "IB Philosophy",
  "IB Psychology",
  "IB Social & Cultural Anthropology",
  "IB World Religions",
  "IB Biology",
  "IB Chemistry",
  "IB Computer Science",
  "IB Design Technology",
  "IB Environmental Systems & Societies",
  "IB Physics",
  "IB Sports, Exercise, & Health Science",
  "IB Dance",
  "IB Film",
  "IB Music",
  "IB Theatre",
  "IB Visual Arts",
  "IB Mathematics: Analysis and Approaches",
  "IB Mathematics: Applications and Interpretation",
];
export const ACADEMIC_INTEREST_OPTIONS = [
  "Biology",
  "Business/Finance",
  "Computer science",
  "Engineering",
  "Economics",
  "Psychology",
  "Mathematics",
  "English",
  "History",
  "Biomedical Engineering",
  "Anthropology",
  "Chemistry",
  "Physics",
  "Ethnic Studies",
  "Women & Gender Studies",
  "African-American Studies",
  "Environmental Science",
  "Linguistics",
  "Urban Studies",
  "Art History",
  "Music",
  "Geosciences",
  "Sociology",
  "Philosophy",
  "Spanish Cultural Studies",
  "French Cultural Studies",
  "German Cultural Studies",
  "Asian Cultural Studies",
  "Hispanic Cultural Studies",
  "Astronomy",
  "Political Science/Public Policy",
  "International Relations",
  "Architecture",
  "Marketing",
  "Communications",
  "Sport Science",
  "Chemical Engineering",
  "Civil Engineering",
  "Environmental Engineering",
  "Dance",
  "Geological Sciences",
  "Data Analytics/Informatics",
  "Health & Society",
  "Public Health",
  "Fashion",
  "Accounting",
  "Aerospace Engineering",
  "Agriculture",
  "Aviation",
  "Theater",
].sort((a, b) => a.localeCompare(b));
export const COLLEGE_OPTIONS_TOP = Object.keys(colleges)
  .filter((college) => colleges[college].rank < 200)
  .sort((a, b) => colleges[a].rank - colleges[b].rank)
  .map((college) => ({
    value: college,
    label: college,
    image: { avatar: true, src: colleges[college].image },
  }));
export const COLLEGE_OPTIONS_T20 = Object.keys(colleges)
  .filter((college) => T20_SCHOOLS.includes(college))
  .sort((a, b) => colleges[a].rank - colleges[b].rank)
  .map((college) => ({
    value: college,
    label: college,
    image: { avatar: true, src: colleges[college].image },
  }));
export const COLLEGE_OPTIONS_ALL = Object.keys(colleges)
  .filter((college) => colleges[college].rank < 1000)
  .map((college) => ({ value: college, label: college }));

export const COLLEGE_RESULTS_SORTING = {
  Accepted: -1,
  Waitlisted: 0,
  Withdrawn: 2,
  Rejected: 1,
};
export const COLLEGE_RESULTS_COLORS = {
  Accepted: "green.600",
  Waitlisted: "yellow.500",
  Withdrawn: "gray.700",
  Rejected: "red.500",
};
export const DEFAULT_PROFILE_DATA = {
  ap_scores: [],
  awards: [],
  colleges: [],
  ecs: [],
  essays: [],
  ethnicity: [],
  advice: [],
  scholarships: [],
  approach: "",
  // future_plans: '',
  highschool_type: "",
  is_test_optional: false,
  sat_superscore: null,
  act_superscore: null,
  application_year: null,
  rank_in_class: "",
  display_name: null,
  bio: "",
  university: "",
  gpa_w: null,
  gpa_uw: null,
  gpa_scale: null,
  sex: "",
  origin_country: "",
  origin_state: "",
  is_first_gen: false,
  is_athlete: false,
  is_legacy: false,
  is_international: false,
  is_low_income: false,
  academic_interests: [],
  additional_info: null,
  consulting: false,
  redactions: {},
  reviews: [],
};
export const AO_NOTES_STRUCTURE = [
  {
    name: "Reader Evaluation",
    metrics: true,
    notes: true,
    repeatable: true,
  },
  {
    name: "Committee Evaluation",
    metrics: false,
    notes: true,
    repeatable: false,
  },
  {
    name: "Interview Evaluation",
    metrics: true,
    notes: true,
    repeatable: false,
  },
];
export const PINNED_PROFILE = "";

function isBoolean(val) {
  return val === true || val === false;
}

export const isCompleteProfile = (profile) =>
  !!profile && !!profile.display_name && !!profile.colleges.length && !!profile.university;

export function createCleanProfile(raw, forceTestOptional) {
  const clean = {};

  if (raw.redactions) {
    clean.redactions = raw.redactions;
  }

  if (raw.ap_scores) {
    clean.ap_scores = [];
    for (const result of raw.ap_scores) {
      if (result.name) {
        clean.ap_scores.push({
          name: result.name,
          score: parseInt(result.score) || "",
        });
      }
    }
  }

  if (raw.awards) {
    clean.awards = [];
    for (const award of raw.awards) {
      if (award.level && award.description) {
        clean.awards.push({
          level: award.level,
          description: award.description,
        });
      }
    }
  }

  if (raw.colleges) {
    clean.colleges = [];
    for (const college of raw.colleges) {
      if (college.name && !!find(college.name)) {
        clean.colleges.push({
          name: college.name,
          result: college.result ?? "",
        });
      }
    }
  }

  if (raw.university) {
    if (!clean.colleges.map((college) => college.name).includes(raw.university)) {
      clean.colleges.push({
        name: raw.university,
        result: "Accepted",
      });
    }
  }

  if (raw.created) {
    clean.created = raw.created;
  }

  if (raw.ecs) {
    clean.ecs = [];
    for (const ec of raw.ecs) {
      if (ec.description && (ec.position || ec.organization)) {
        clean.ecs.push({
          position: ec.position,
          organization: ec.organization,
          description: ec.description,
        });
      }
    }
  }

  if (raw.essays) {
    clean.essays = [];
    for (const e of raw.essays) {
      e.college = e.college?.trim() ?? "";
      e.prompt = e.prompt?.trim();
      e.content = e.content?.trim();
      if (!e.type) e.type = "Main Essay";
      if (e.prompt.length > 0 && e.content.length > 0) {
        clean.essays.push({
          college: e.college,
          content: e.content,
          type: e.type,
          prompt: e.prompt,
        });
      }
    }
  }

  if (raw.ethnicity) {
    clean.ethnicity = [];
    for (let eth of raw.ethnicity) {
      eth = eth.trim();
      if (eth === "(Europe)") {
        eth = "White";
      } else if (eth === "(Carribean)") {
        eth = "Hispanic";
      } else if (eth === "(India)") {
        eth = "Asian";
      } else {
        eth = eth.split("(")[0].trim();
      }
      clean.ethnicity.push(eth);
    }
    // make a set to remove duplicates
    clean.ethnicity = [...new Set(clean.ethnicity)].filter((e) => !!e);
  }

  if (raw.additional_info && raw.additional_info.length > 5) {
    clean.additional_info = raw.additional_info.substring(0, 650 * 12);
  }

  if (raw.advice) {
    clean.advice = [];
    for (const advice of raw.advice) {
      clean.advice.push({
        prompt: advice.prompt,
        content: advice.content,
      });
    }
  }

  if (raw.scholarships) {
    clean.scholarships = [];
    for (const scholarship of raw.scholarships) {
      clean.scholarships.push({
        name: scholarship.name,
        hasFile: scholarship.hasFile,
      });
    }
  }

  if (isBoolean(raw.is_test_optional)) {
    clean.is_test_optional = raw.is_test_optional;
    if (clean.is_test_optional) {
      clean.sat_superscore = null;
      clean.act_superscore = null;
    } else {
      if (raw.sat_superscore) clean.sat_superscore = parseInt(raw.sat_superscore) || null;
      if (raw.act_superscore) clean.act_superscore = parseInt(raw.act_superscore) || null;
    }
  } else {
    if (raw.sat_superscore) clean.sat_superscore = parseInt(raw.sat_superscore) || null;
    if (raw.act_superscore) clean.act_superscore = parseInt(raw.act_superscore) || null;
    if (forceTestOptional) {
      clean.is_test_optional =
        (clean.sat_superscore === null || clean.sat_superscore === undefined) &&
        (clean.act_superscore === null || clean.act_superscore === undefined);
    }
  }

  if (raw.ao_notes) {
    clean.ao_notes = [];
    for (const evaluation of raw.ao_notes) {
      clean.ao_notes.push({
        name: evaluation.name,
        metrics: [...evaluation.metrics],
        overall: evaluation.overall,
      });
    }
  }

  if ("consulting" in raw) {
    clean.consulting = raw.consulting;
    if (!raw.consulting) clean.mentoringPackages = [];
  }
  if (raw.approach) clean.approach = raw.approach;
  // if (raw.future_plans) clean.future_plans = raw.future_plans ?? null
  if (raw.highschool_type) {
    if (HS_TYPE_OPTIONS.includes(raw.highschool_type)) {
      clean.highschool_type = raw.highschool_type;
    } else {
      clean.highschool_type = "Public";
    }
  }
  if (raw.application_year) clean.application_year = parseInt(raw.application_year) || null;
  if (raw.from_file) clean.from_file = raw.from_file;
  if (raw.display_name) clean.display_name = raw.display_name;
  if (raw.bio) clean.bio = raw.bio;
  if (raw.university) clean.university = raw.university;
  if (raw.gpa_w) clean.gpa_w = parseFloat(raw.gpa_w) || null;
  if (raw.gpa_uw) clean.gpa_uw = parseFloat(raw.gpa_uw) || null;
  if (raw.gpa_scale) clean.gpa_scale = parseFloat(raw.gpa_scale) || null;
  if (raw.rank_in_class) clean.rank_in_class = raw.rank_in_class;
  if (raw.sex) clean.sex = raw.sex;
  if (raw.origin_country) {
    clean.origin_country = raw.origin_country;
    if (clean.origin_country === "United States") {
      if (raw.origin_state) clean.origin_state = raw.origin_state;
    } else {
      clean.origin_state = null;
    }
  }
  if (isBoolean(raw.is_first_gen)) clean.is_first_gen = raw.is_first_gen;
  if (isBoolean(raw.is_athlete)) clean.is_athlete = raw.is_athlete;
  if (isBoolean(raw.is_legacy)) clean.is_legacy = raw.is_legacy;
  if (isBoolean(raw.is_low_income)) clean.is_low_income = raw.is_low_income;
  if (isBoolean(raw.is_international)) clean.is_international = raw.is_international;
  if (raw.academic_interests) clean.academic_interests = raw.academic_interests;

  const completenessScore = Number(raw.completeness_score);
  if (completenessScore && !isNaN(completenessScore)) {
    clean.completeness_score = completenessScore;
  }

  return clean;
}

export function generateReferralCode() {
  const dictionary = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  var buffer = "";
  for (var i = 0; i < 4; i++)
    buffer += dictionary.charAt(Math.floor(Math.random() * dictionary.length));
  return buffer;
}
