import { useEffect, useState } from "react";
import { Image, chakra, useToast } from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import { find } from "@/utils/data/colleges-data";
import OptimizedImage from "../shared/OptimizedImage";

export function VerifiedCheck({ size, university, ...rest }) {
  const [hover, setHover] = useState(false);
  const [toastId, setToastId] = useState();
  const toast = useToast();

  useEffect(() => {
    if (hover) {
      setToastId(
        toast({
          title: find(university)?.name,
          description: "This acceptance is verified.",
          status: "success",
          duration: 5000,
          isClosable: false,
          position: "top",
        }),
      );
    } else {
      toast.close(toastId);
    }
  }, [hover]);

  return (
    <chakra.span display="inline-block" w={size} h={size} pos="relative" {...rest}>
      <OptimizedImage
        src={"/images/icons/verified.png"}
        mr={0.5}
        alt={`Verified acceptance to ${university}`}
        position="relative"
        layout="fill"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      />
    </chakra.span>
  );
}

export function ConditionalVerifiedCheck({ verifications, university, size = "1.25em", ...rest }) {
  if (verifications && verifications[university]?.status === "verified") {
    return <VerifiedCheck size={size} university={university} {...rest} />;
  } else {
    return null;
  }
}
